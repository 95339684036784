import React, { useState } from "react";
import { Helmet } from "react-helmet";
import InternalLink from "../InternalLink";
import { Heading, Container } from "../../sdk/Layout";
import { Buttons, Button } from "../../sdk/Button";
import { SectionExtended, InfoItem, InfoItemList, Spacer } from "./style";
import useTranslation from "../../../lang/hooks/useTranslation";
import Icons from "../../simpleViews/LandingPrivateLabel/components/PrivateLabelBenefits/Icons";

function Item({ icon, title, description }) {
    return (
        <InfoItem>
            <div className="inner">
                <Icons icon={icon} className="icon" />
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </InfoItem>
    );
}

const Pagination = (data) => {
    return data.map(({ icon, title, paragraph }) => (
        <Item key={title} icon={icon} title={title} description={paragraph} />
    ));
};

function Benefits({ config }) {
    const [_t] = useTranslation();
    if (config == null || config.length === 0) return null;

    const [more, setMore] = useState(false);
    const handleMore = () => {
        setMore(!more);
    };

    const initialData = [...config].slice(0, 3);
    const restData = [...config].slice(3);

    return (
        <SectionExtended>
            <Container>
                <Heading isCentered>
                    <h3>{_t("Recibe estos beneficios y más")}</h3>
                </Heading>
                <InfoItemList>
                    {initialData.map(({ icon, title, paragraph }) => (
                        <Item
                            key={title}
                            icon={icon}
                            title={title}
                            description={paragraph}
                        />
                    ))}

                    {!more ? (
                        <>
                            <Spacer height="20" />
                            <Buttons isCentered>
                                <Button onClick={handleMore}>
                                    {_t("Ver todos los beneficios")}
                                </Button>
                            </Buttons>
                        </>
                    ) : (
                        Pagination(restData)
                    )}
                </InfoItemList>
            </Container>
        </SectionExtended>
    );
}

export default Benefits;
