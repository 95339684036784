import styled from "styled-components";
import { Section } from "../../sdk/Layout";

export const SectionExtended = styled(Section)`
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
`;

export const InfoItem = styled.div`
    border-radius: 10px;
    background-color: ${(props) => props.theme.white};
    border: solid 1px ${(props) => props.theme.grayLight};
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02);
    padding: 30px;
    .inner {
        position: relative;
        padding-left: 60px;
        padding-top: 6px;
        .icon {
            width: 35px !important;
            height: 35px !important;
            position: absolute;
            left: 0px;
            top: 0px;
            color: ${(props) => props.theme.primaryDarkMode};
        }
        h3 {
            color: ${(props) => props.theme.primaryDarkMode};
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            color: ${(props) => props.theme.gray};
            font-size: 15px;
            line-height: 22px;
        }
    }
    &.contact-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
        color: ${(props) => props.theme.primaryDarkMode};
        transition: all 200ms ease-in-out;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const InfoItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1%;
    ${InfoItem} {
        margin: 1%;
        width: 31.333%;
    }
    @media (max-width: 768px) {
        ${InfoItem} {
            margin: 0.5%;
            width: 49%;
        }
    }
    @media (max-width: 540px) {
        ${InfoItem} {
            width: 99%;
        }
    }
`;

export const Spacer = styled.div`
  height: ${(props) => props.height ?? '10'}px;
  width: 100%;
`;